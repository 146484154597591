import { createContext, useContext, useState } from "react";
import { BlockBlobClient } from "@azure/storage-blob";

import * as API from "../api";

export function useUploadsInternal() {
    const [orderUploads, setOrderUploads] = useState([]);

    const addOrderUpload = (order, asset, files) => {
        setOrderUploads((uploads) => {
            return [...uploads, { order, asset, files }];
        });

        /*const uploadPromises = */files.map((file) => {
            return uploadFile(asset, file, (ev) => {
                console.log("ev", ev);
                setOrderUploads((uploads) => {
                    return uploads.map((orderUpload) => {
                        if (orderUpload.order.id === order.id) {
                            return {
                                ...orderUpload,
                                files: orderUpload.files.map((orderFile) => {
                                    if (orderFile.fileObject.name === file.fileObject.name) {
                                        return {
                                            ...orderFile,
                                            loadedBytes: ev.loadedBytes,
                                            completed: ev.loadedBytes === file.fileObject.size,
                                        };
                                    }
                                    return orderFile;
                                }),
                            };
                        }
                        return orderUpload;
                    });
                });
            });
        });

        // Promise.all(uploadPromises)
        //     .then(() => {
        //         setOrderUploads((orderUploads) => {
        //             return [...orderUploads, { order, asset, files }];
        //         });
        //     })
        //     .catch((error) => {
        //         console.error("Error uploading files", error);
        //     });
    };

    return {
        orderUploads,
        addOrderUpload,
    };
}

export const UploadsContext = createContext();

export function useUploads() {
    return useContext(UploadsContext);
}

function uploadFile(asset, file, onUploadProgress) {
    return new Promise((resolve, reject) => {
        (async () => {
            try {
                const newFile = await API.createAssetFile(asset.id, {
                    name: file.fileObject.name,
                });
                const { url: uploadSAS } = await API.getFileUploadSAS(asset.id, newFile.itemId ?? newFile.id);
            
                const blockBlobClient = new BlockBlobClient(uploadSAS);
                await blockBlobClient.uploadData(file.fileObject, {
                    onProgress: (ev) => {
                        onUploadProgress(ev);
                    },
                });
                // setTimeout(() => {
                //     onUploadProgress({ loadedBytes: file.fileObject.size / 10 });
                // }, 1000);
                // setTimeout(() => {
                //     onUploadProgress({ loadedBytes: (file.fileObject.size / 10) * 2 });
                // }, 2000);
                // setTimeout(() => {
                //     onUploadProgress({ loadedBytes: (file.fileObject.size / 10) * 3 });
                // }, 3000);
                // setTimeout(() => {
                //     onUploadProgress({ loadedBytes: (file.fileObject.size / 10) * 4 });
                // }, 4000);
                // setTimeout(() => {
                //     onUploadProgress({ loadedBytes: (file.fileObject.size / 10) * 5 });
                // }, 5000);
                // setTimeout(() => {
                //     onUploadProgress({ loadedBytes: (file.fileObject.size / 10) * 6 });
                // }, 6000);
                // setTimeout(() => {
                //     onUploadProgress({ loadedBytes: (file.fileObject.size / 10) * 7 });
                // }, 7000);
                // setTimeout(() => {
                //     onUploadProgress({ loadedBytes: (file.fileObject.size / 10) * 8 });
                // }, 8000);
                // setTimeout(() => {
                //     onUploadProgress({ loadedBytes: (file.fileObject.size / 10) * 9 });
                // }, 9000);
                // setTimeout(() => {
                //     onUploadProgress({ loadedBytes: file.fileObject.size });
                // }, 10000);
                resolve();
            } catch (error) {
                reject(error);
            }
        })();
    });
}