import { useState, useCallback, useEffect, useRef, createContext, useContext } from "react";

import * as API from "../api";
import { APITOKEN_STORAGE_KEY_PREFIX, extractInfoFromToken, getUserFromStorage, TOKEN_CHECK_INTERVAL, USERTOKEN_STORAGE_KEY, validateToken } from "../authUtils";

export function useAuthInternal() {

    const checkTokenInterval = useRef();
    const [userToken, setUserToken] = useState(null);
    const [initialized, setInitialized] = useState(false);

    useEffect(
        () => {
            const token = getUserFromStorage();
            const validToken = validateToken(token);
            if (validToken) {
                setUserToken(token);
            }

            setInitialized(true);

            checkTokenInterval.current = setInterval(
                () => {
                    const token = getUserFromStorage();
                    const validToken = validateToken(token);
                    if (!validToken) {
                        setUserToken(null);
                        localStorage.removeItem(USERTOKEN_STORAGE_KEY);
                        clearInterval(checkTokenInterval.current);
                    }
                },
                TOKEN_CHECK_INTERVAL
            );

            return () => {
                clearInterval(checkTokenInterval.current);
            };
        },
        []
    );

    const login = useCallback(
        async (data, navigate) => {
            const response = await API.authenticate(data);
            setUserToken(response);
            localStorage.setItem(USERTOKEN_STORAGE_KEY, response);
            if (navigate) {
                navigate("/");
            }
        },
        [],
    );

    const logout = useCallback(
        (navigate) => {
            setUserToken(null);
            localStorage.removeItem(USERTOKEN_STORAGE_KEY);
            Object.keys(localStorage)
                .filter(key => key.startsWith(APITOKEN_STORAGE_KEY_PREFIX))
                .forEach(key => localStorage.removeItem(key));
            if (navigate) {
                navigate("/login");
            }
        },
        []
    );

    // const register = useCallback(
    //     async (data) => {
    //         const response = await API.register(data);
    //         setUserToken(response);
    //         localStorage.setItem(USERTOKEN_STORAGE_KEY, response);
    //     },
    //     [],
    // );

    return {
        initialized,
        login,
        logout,
        // register,
        userToken,
        isLoggedIn: !!userToken,
        ...extractInfoFromToken(userToken)
    };
}

export const AuthContext = createContext();
export const useAuth = () => {
    return useContext(AuthContext);
};
