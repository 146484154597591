import { jwtDecode } from "jwt-decode";

export function validateToken(token) {
    try {
        const decoded = jwtDecode(token);
        return (decoded.exp * 1000) > (Date.now() + TOKEN_PADDING);
    } catch (error) {
        if (!error.message?.includes("must be a string")) {
            console.log("Error validating token", error);
        }
        return false;
    }
}

export function getUserFromStorage() {
    try {
        const user = localStorage.getItem(USERTOKEN_STORAGE_KEY);
        return user;
    } catch (error) {
        console.log("Error loading user from storage", error);
        localStorage.removeItem(USERTOKEN_STORAGE_KEY);
        return null;
    }
}

export function extractInfoFromToken(token) {
    try {
        const decoded = jwtDecode(token);
        return {
            username: decoded.username,
            roles: decoded.accessroles,
        };
    } catch (error) {
        return {
            username: null,
            roles: [],
        };
    }
}

export function getUserRoles() {
    const { roles } = extractInfoFromToken(getUserFromStorage());
    return roles;
}

export const USERTOKEN_STORAGE_KEY = "usertoken";
export const APITOKEN_STORAGE_KEY_PREFIX = "apitoken-";
export const TOKEN_PADDING = 1000 * 60 * 5;
export const TOKEN_CHECK_INTERVAL = 1000 * 60;