import { useNavigate } from "react-router-dom";

import logo from "/JF_Symbol_White.svg";
import { useAuth } from "./hooks/useAuth";

import "./Header.css";

function Header() {

    const { username, logout } = useAuth();
    const navigate = useNavigate();

    return (
        <header>
            <div className="content">
                <div className="product-info">
                    <img className="logo" src={logo}></img>
                    <div className="product-name">JuneFirst <span>Translation Service</span></div>
                </div>
                <div className="user">
                    {username && (
                        <a onClick={() => logout(navigate)}>Logout</a>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;