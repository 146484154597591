import { useCallback, useEffect, useState } from "react";

import { getOrders, removeOrder, createOrder } from "../api";

export function useOrders() {
    const [items, setItems] = useState(null);

    useEffect(
        () => {
            (async () => {
                const orders = await getOrders({ isTemplate: false });
                setItems(orders);
            })();
        },
        []
    );

    const get = useCallback(
        (id) => {
            return items.find((item) => item.id === id);
        },
        [items]
    );

    const create = useCallback(
        async (data) => {
            const order = await createOrder(data);
            setItems((items) => [...items, order]);
            return order;
        },
        []
    );

    const add = useCallback(
        async (order) => {
            setItems((items) => [...items, order]);
        },
        []
    );

    const del = useCallback(
        async (id) => {
            await removeOrder(id);
            setItems((items) => items.filter((item) => item.id !== id));
        },
        []
    );

    const remove = useCallback(
        async (id) => {
            setItems((items) => items.filter((item) => item.id !== id));
        },
        []
    );

    return {
        items,
        get,
        create,
        add,
        remove,
        del,
    };
}