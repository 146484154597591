import { useEffect } from "react";
// import { createPortal } from "react-dom";
// import { useBlocker } from "react-router-dom";

// import Button from "./Button";

export function Blocker({
	isActive = false,
	text = "You have not saved your changes, are you sure you want to leave this page and discard them?",
}) {

    useEffect(
        () => {
            const handleBeforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };

            if (isActive) {
                window.addEventListener("beforeunload", handleBeforeUnload);
            } else {
                window.removeEventListener("beforeunload", handleBeforeUnload);
            }

            return () => {
                window.removeEventListener("beforeunload", handleBeforeUnload);
            };
        },
        [text, isActive]
    );

	// const blocker = useBlocker(({ currentLocation, nextLocation }) => isActive && currentLocation.pathname !== nextLocation.pathname);

	// if (blocker.state === "blocked") {
	// 	return createPortal(
	// 		<div className="blocker">
	// 			<div className="overlay"></div>
	// 			<div className="content">
	// 				<p>{text}</p>
	// 				<div className="actions">
	// 					<Button
	// 						label="Leave"
	// 						className="leave"
	// 						onClick={() => blocker.proceed()}
	// 					/>
	// 					<Button
	// 						title="Stay"
	// 						className="stay"
	// 						onClick={() => blocker.reset()}
	// 					/>
	// 				</div>
	// 			</div>
	// 		</div>,
	// 		document.body
	// 	);
	// }

	return null;
}