import { useState } from "react";
// import { formatDuration/*, formatSize*/ } from "../utils";

import "./FileList.css";

function FileList(props) {
    const {
        files,
        color,
        secondaryColor,
        onNewFilesAdded,
        onRemoveFile,
        includeDetails,
    } = props;

    const [isHovering, setIsHovering] = useState(false);

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = Array.from(e.dataTransfer.files);
        onNewFilesAdded(files);
        setIsHovering(false);
    };

    const handleDragEnter = (e) => {
        if (e.currentTarget.contains(e.relatedTarget)) {
            return;
        }
        e.preventDefault();
        setIsHovering(true);
    };

    const handleDragLeave = (e) => {
        if (e.currentTarget.contains(e.relatedTarget)) {
            return;
        }
        e.preventDefault();
        setIsHovering(false);
    };

    const handleDragOver = (e) => {
        if (e.currentTarget.contains(e.relatedTarget)) {
            return;
        }
        e.preventDefault();
    };

    const allowAddingFiles = !!onNewFilesAdded;

    const dndHandlers = allowAddingFiles
        ? {
            onDrop: handleDrop,
            onDragEnter: handleDragEnter,
            onDragLeave: handleDragLeave,
            onDragOver: handleDragOver,
        }
        : {};

    return (
        <div
            className={`file-list ${includeDetails ? "with-details" : ""}`}
            {...dndHandlers}
        >
            {allowAddingFiles && (
                <div className="upload-archive">
                    <label
                        htmlFor="file-upload"
                        style={{
                            borderColor: isHovering ? secondaryColor : color,
                            backgroundColor: isHovering ? color : secondaryColor,
                        }}
                    >
                        Click to select files<br/>
                        or drop them here
                    </label>
                    <input
                        type="file"
                        multiple={true}
                        id="file-upload"
                        onChange={(e) => {
                            const files = Array.from(e.target.files);
                            onNewFilesAdded(files);
                        }}
                    />
                </div>
            )}
            <div className="files">
                {files?.map((file) => {
                    // const progressPercentage = Math.round(file.loadedBytes / file.size * 100);
                    return (
                        <div className="file" key={file.id ?? file.name}>
                            <img src={file.thumbnailURL} />
                            <div className="file-info">
                                <div className="file-name" title={file.name}>{file.name}</div>
                                {/* {includeDetails && (
                                    <div className="file-details"> */}
                                        {/* {!isNaN(progressPercentage) && (
                                            <div className={`file-progress ${progressPercentage === 100 ? "hide": ""}`}>
                                                <div
                                                    className={`file-progress-bar ${progressPercentage === 100 ? "done" : ""}`}
                                                    style={{
                                                        maxWidth: `${progressPercentage}%`,
                                                    }}
                                                />
                                                <div className="file-progress-percentage">
                                                    {progressPercentage}%
                                                </div>
                                            </div>
                                        )} */}
                                        {/* {file.processing && isNaN(progressPercentage) && (
                                            <div className="file-processing">
                                                Processing...
                                            </div>
                                        )} */}
                                        {/* <div className={`file-duration ${!allowAddingFiles || progressPercentage === 100 ? "show" : ""}`}>
                                            {formatDuration(file.duration)}
                                        </div> */}
                                        {/* {progressPercentage === 100 && (
                                            <div className="file-upload-finished">
                                                Uploaded
                                            </div>
                                        )} */}
                                    {/* </div>
                                )} */}
                                {onRemoveFile && (
                                    <button
                                        className="file-remove"
                                        onClick={() => onRemoveFile(file)}
                                    >
                                        REMOVE
                                    </button>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default FileList;