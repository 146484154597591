// import { useEffect } from "react";

import { useUploads } from "../hooks/useUploads";
import { useAuth } from "../hooks/useAuth";

import { Blocker } from "./Blocker";

import "./UploadStatus.css";

function UploadStatus() {
    const { isLoggedIn } = useAuth();
    const uploads = useUploads();

    // useEffect(
    //     () => {
    //         console.log("uploads.orderUploads", uploads.orderUploads);
    //     },
    //     [uploads.orderUploads]
    // );
    
    if (!isLoggedIn) {
        return null;
    }

    const hasUploadsNotFinished = uploads.orderUploads.some((orderUpload) => {
        return orderUpload.files.some((file) => {
            return file.loadedBytes < file.size;
        });
    });

    return (
        <div className="upload-status">
            {/* {uploads.orderUploads.map(({ order, files }) => {
                console.log("order", order);
                return (
                    <div key={order.id} className="order-upload">
                        <h2>{order.name}</h2>
                        <ul>
                            {files.map((file) => {
                                const progressPercentage = Math.round(file.loadedBytes / file.size * 100);
                                return (
                                    <li key={file.fileObject.name}>
                                        {file.fileObject.name} - {progressPercentage}%
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            })} */}
            <Blocker isActive={hasUploadsNotFinished} text="Files are being uploaded, if you navigate away, the order will be cancelled." />
        </div>
    );
}

export default UploadStatus;